import * as React from "react";
import { DateAndTimeGridCell } from "../common/DateAndTimeGridCell";
import { Tooltip } from "@mui/material";
import {
  formatFileSize,
  formatDate_12h,
  capitalize,
  getCampaignNameString,
} from "../../services/Utils";
import FileUploadStatus from "../clusterSimulations/ClusterFileUploadStatus";
import ClusterImageUploadDataTableRowActions from "./ClusterImageUploadDataTableRowActions";
import Constants, { DEPLOYMENT_PACKAGE_TYPE } from "../../services/Constants";
import TextField from "@mui/material/TextField";

const UploadStatusInput = (props) => {
  return (
    <>
      <TextField
        label="Value"
        variant="standard"
        style={{ minWidth: 120 }}
        select
        SelectProps={{
          native: true,
        }}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
      >
        {Object.entries(Constants.UPLOAD_STATUS).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </TextField>
    </>
  );
};

const PackageTypeInput = (props) => {
  return (
    <>
      <TextField
        label="Value"
        variant="standard"
        style={{ minWidth: 120 }}
        select
        SelectProps={{
          native: true,
        }}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
      >
        {Object.entries(DEPLOYMENT_PACKAGE_TYPE).map(([key, value]) => (
          <option key={key} value={value}>
            {value}
          </option>
        ))}
      </TextField>
    </>
  );
};

const getClusterImageUploadDataColumns = () => {
  const filterOperators = [
    {
      value: "equals",
      label: "Equals",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (params) => {
          return params.value.toLowerCase() === filterItem.value.toLowerCase();
        };
      },
      InputComponent: UploadStatusInput,
      InputComponentProps: { type: "string" },
    },
    // Add more custom operators as needed
  ];

  const customFilterOperatorsForPackageType = [
    {
      value: "equals",
      label: "Equals",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (params) => {
          return params.value.toLowerCase() === filterItem.value.toLowerCase();
        };
      },
      InputComponent: PackageTypeInput,
      InputComponentProps: { type: "string" },
    },
    // Add more custom operators as needed
  ];

  return [
    {
      field: "simulationName",
      headerName: "Name",
      flex: 2,
      editable: false,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value || "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   field: "fileType",
    //   headerName: "Type",
    //   flex: 1,
    //   editable: false,
    // },
    // {
    //   field: "fileSize",
    //   headerName: "Size",
    //   type: "number",
    //   flex: 0.5,
    //   editable: false,
    //   filterable: false,
    //   // valueFormatter: ({ value }) => formatFileSize(value),
    //   renderCell: (data) => {
    //     const fileSize = formatFileSize(parseInt(data?.value));
    //     return (
    //       <Tooltip title={fileSize}>
    //         <p
    //           style={{
    //             overflow: "hidden",
    //             textOverflow: "ellipsis",
    //           }}
    //         >
    //           {fileSize || "-"}
    //         </p>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "version",
      headerName: "Version",
      description: "Version of the software",
      sortable: true,
      flex: 1,
      renderCell: (data) => (
        <Tooltip title={data?.value}>
          <p
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {data?.value || "-"}
          </p>
        </Tooltip>
      ),
    },
    // {
    //   field: "campaignType",
    //   headerName: "Package Type",
    //   description: "Package type",
    //   sortable: false,
    //   flex: 0.7,
    //   filterOperators: customFilterOperatorsForPackageType,
    //   renderCell: (data) => (
    //     <Tooltip title={getCampaignNameString(data?.value)}>
    //       <p
    //         style={{
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //         }}
    //       >
    //         {getCampaignNameString(data?.value) || "-"}
    //       </p>
    //     </Tooltip>
    //   ),
    // },
    {
      field: "uploadedBy",
      headerName: "Uploaded By",
      description: "The person who uploaded the software",
      sortable: true,
      flex: 0.7,
      valueFormatter: ({ value }) => capitalize(value),
    },
    {
      field: "uploadStatus",
      headerName: "Upload Status",
      description: "Upload Status",
      sortable: false,
      flex: 1,
      filterOperators,
      renderCell: (data) => {
        return <FileUploadStatus status={data.row.uploadStatus} />;
      },
    },

    {
      field: "createdAt",
      headerName: "Created At",
      description: "Time of creation",
      sortable: true,
      flex: 0.75,
      type: "dateTime",
      renderCell: (data) => <DateAndTimeGridCell value={data?.value} />,
    },
    {
      field: "none",
      headerName: "Actions",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (data) => (
        <ClusterImageUploadDataTableRowActions data={data} />
      ),
    },
  ];
};

export { getClusterImageUploadDataColumns };
