import React, { memo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Input,
} from "@mui/material";
import Draggable from "react-draggable";
import CommentIcon from "@mui/icons-material/Comment";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { formatDate, overviewListItemStyles } from "../../services/Utils";
import { useQueryClient, useMutation } from "react-query";
import { updateClusterImageDescription } from "../../services/cluster";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ width: "90%", maxWidth: "90%" }} {...props} />
    </Draggable>
  );
}

const CommentsList = ({
  toggleDetailsDrawer,
  simulationDetails,
  theme,
  open,
  setOpen,
  iFrameTrigger,
}) => {
  const dialogType = "details";
  // var editState = false;
  const queryClient = useQueryClient();
  const [editState, setEditState] = React.useState(false);
  const editMode = () => {
    setEditState(!editState);
    console.log(editState);
  };
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (open) {
      if (open === dialogType) {
        setOpen(false);
        iFrameTrigger(false);
      } else {
        setOpen(dialogType);
        iFrameTrigger(dialogType);
      }
    } else {
      setOpen(dialogType);
      iFrameTrigger(dialogType);
    }
  };

  var description = "";

  const updateSimulationDescription = (simulationUploadId, description) => {
    const payload = {
      simulationUploadId: simulationUploadId,
      description: description,
    };
    updateSimDescriptionToDb(payload);
  };

  const { mutate: updateSimDescriptionToDb } = useMutation(
    updateClusterImageDescription,
    {
      onSuccess: async ({ data }) => {},
      onError: (err) => {},
      onSettled: () => {
        queryClient.invalidateQueries("fetchSimulationDetails");
      },
    }
  );
  return (
    <>
      {/* <Fab onClick={handleClickOpen} variant="extended" size="medium"> */}
      <InfoIcon
        onClick={handleClickOpen}
        variant="extended"
        sx={{ mr: 1 }}
        style={{
          cursor: "pointer",
          marginBottom: "0.5em",
          color: open === dialogType ? theme.palette.primary[500] : "darkgrey",
        }}
      />
      {/* Details */}
      {/* </Fab> */}

      {/* <Dialog
        id="comments-list-dialog"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="draggable-handle"
          >
            <div>
              <Typography variant="h6">Details</Typography>
            </div>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent> */}
      {open === dialogType ? (
        <Grid
          container
          style={{
            backgroundColor: "#F2F2F2",
            width: "320px",
            background: "#f2f2f2",
            flexDirection: "column",
            position: "absolute",
            right: "78px",
            top: "60px",
            height: "calc(100vh - 80px)",
          }}
        >
          {/* <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}> */}
          <List style={{ borderBottom: "1px solid #FFFFFF" }}>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", color: "gray" }}
                  >
                    Details
                  </Typography>
                }
                // secondary={simulationDetails?.simulationName}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary={
                  <div
                    style={{ backgroundColor: "#FFFFFF", textAlign: "center" }}
                  >{`Upload status : ${simulationDetails?.uploadStatus}`}</div>
                }
                // secondary={simulationDetails?.uploadStatus}
              />
            </ListItem>
          </List>
          <List>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "1em",
                      // color: "gray"
                    }}
                  >
                    Simulation Name
                  </Typography>
                }
                secondary={simulationDetails?.simulationName}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Simulation Upload Id"
                secondary={simulationDetails?.simulationUploadId}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Simulation Type"
                secondary={simulationDetails?.simulationType}
              />
            </ListItem>
            {/* <ListItem {...overviewListItemStyles}>
                  <ListItemText
                    primary="File Size"
                    secondary={formatFileSize(simulationDetails?.fileSize)}
                  />
                </ListItem> */}
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Version"
                secondary={simulationDetails?.version}
              />
            </ListItem>
            {/* <ListItem {...overviewListItemStyles}>
                  <ListItemText
                    primary="Base Version"
                    secondary={simulationDetails?.baseVersion || "-"}
                  />
                </ListItem> */}

            {/* </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}> */}

            <ListItem {...overviewListItemStyles}>
              {editState && (
                <div>
                  <ListItemText primary="Description" />
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      gap: "7px",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder={"New Description"}
                      onChange={(e) => {
                        description = e.target.value;
                      }}
                    />
                    <div style={{ cursor: "pointer" }}>
                      <IconButton
                        onClick={() => {
                          console.log(description);
                          simulationDetails.description = description;
                          updateSimulationDescription(
                            simulationDetails?.simulationUploadId,
                            description
                          );
                          editMode();
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          editMode();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
              {!editState && (
                <>
                  <ListItemText
                    primary="Description"
                    secondary={simulationDetails?.description}
                  />
                  <EditIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => {
                      editMode();
                    }}
                  />
                </>
              )}
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="ChecksumSHA256"
                secondary={simulationDetails?.checksumSHA256 || "-"}
                secondaryTypographyProps={{
                  style: {
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              />
            </ListItem>

            {/* </Grid>
          <Grid item xs={4} sx={{ borderRight: 1, borderColor: "divider" }}> */}

            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Uploaded By"
                secondary={simulationDetails?.uploadedBy}
              />
            </ListItem>
            <ListItem {...overviewListItemStyles}>
              <ListItemText
                primary="Updated At"
                secondary={formatDate(simulationDetails?.updatedAt)}
              />
            </ListItem>
            {/* <ListItem {...overviewListItemStyles}>
                  <ListItemText
                    primary="Campaign"
                    secondary={getCampaignNameString(
                      simulationDetails?.campaignType
                    )}
                  />
                </ListItem> */}
          </List>
          {/* </Grid> */}
        </Grid>
      ) : null}

      {/* </DialogContent>
        <DialogActions></DialogActions>
      </Dialog> */}
    </>
  );
};

export default memo(CommentsList);
