import React, { useRef, useState, memo } from "react";
import { Box, Fab, Drawer, Typography } from "@mui/material";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useTheme } from "@mui/material/styles";
import SimulationOverview from "./SimulationOverview";
import SimulationViewer from "./SimulationViewer";
import CommentsList from "./comments/CommentsList";
import { getColumnsData } from "../release/ReleaseColumns";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const SimulationPreview = ({ simulationDetails }) => {
  const dialogType = "filter";
  const theme = useTheme();
  const iframeRef = useRef(null);
  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [showCommentsTable, setShowCommentsTable] = useState(false);
  const [addComment, setAddComment] = useState(null);
  const [open, setOpen] = useState(false);
  // const { imageId } = props;

  const columns = getColumnsData({ hideActions: false });
  const clusterViewerRef = useRef(null);
  const enterFullscreen = () => {
    const elem = clusterViewerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  const toggleDetailsDrawer = () => {
    setCommentsDrawerOpen(false);
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  const toggleCommentsDrawer = () => {
    setDetailsDrawerOpen(false);
    setCommentsDrawerOpen(!commentsDrawerOpen);
  };
  const toggleCommentsTable = () => {
    setShowCommentsTable(!showCommentsTable);
  };

  const handleFilterClick = () => {
    if (open) {
      if (open === dialogType) {
        setOpen(false);
        iFrameTrigger(false);
      } else {
        setOpen(dialogType);
        iFrameTrigger(dialogType);
      }
    } else {
      setOpen(dialogType);
      iFrameTrigger(dialogType);
    }
  };

  const iFrameTrigger = (dialogType) => {
    const iframe = iframeRef?.current;
    if (!iframe || !iframe.contentWindow) return;
    if (dialogType === "filter") {
      iframe.contentWindow.postMessage(
        {
          item: "Filter",
          action: "on",
        },
        "*"
      );
      console.log("Filter On message sent!");
    } else {
      iframe.contentWindow.postMessage(
        {
          item: "Filter",
          action: "off",
        },
        "*"
      );
      console.log("Filter Off message sent!");
    }
  };

  return (
    <>
      <Box style={{ display: "flex" }}>
        <Typography variant="h4" style={{ marginLeft: "25px" }}>
          {simulationDetails?.simulationName}
        </Typography>
        <Typography variant="caption">{simulationDetails?.version}</Typography>
      </Box>
      <Box
        id="test"
        style={{ padding: "0" }}
        sx={{
          height: "calc(100vh - 138px)",
          width: "100%",
          padding: "0px",
          display: "grid",
          gridTemplateColumns: "auto 50px",
        }}
        ref={clusterViewerRef}
      >
        <SimulationViewer
          id="cluster-viewer"
          iframeRef={iframeRef}
          src={
            // TODO: include the croudfront url instead of the s3 object url
            simulationDetails?.downloadUrl
          }
          customCSS={`
          `}
          open={open}
        />

        <div
          id="cluster-preview-controls"
          // style={{
          //   display: "flex",
          //   position: "absolute",
          //   right: "26px",
          //   margin: "8px",
          //   width: "275px",
          //   justifyContent: "space-between",
          // }}
          style={{
            display: "flex",
            margin: "8px",
            width: "100%",
            alignItems: "center",
            flexDirection: "column",
            padding: "0",
          }}
        >
          {/* <div style={{ marginBottom: "0.5em" }}> */}
          <FilterAltIcon
            variant="extended"
            sx={{ mr: 1 }}
            style={{
              cursor: "pointer",
              marginBottom: "0.5em",
              color:
                open === dialogType ? theme.palette.primary[500] : "darkgrey",
            }}
            onClick={handleFilterClick}
          />
          {/* </div> */}

          <CommentsList
            open={open}
            setOpen={setOpen}
            iFrameTrigger={iFrameTrigger}
            theme={theme}
            showCommentsTable={showCommentsTable}
            toggleCommentsTable={toggleCommentsTable}
            simulationUploadId={simulationDetails?.simulationUploadId}
            toggleCommentsDrawer={toggleCommentsDrawer}
            simulationName={simulationDetails?.simulationName}
            simulationType={simulationDetails?.simulationType}
          />

          <SimulationOverview
            open={open}
            setOpen={setOpen}
            iFrameTrigger={iFrameTrigger}
            theme={theme}
            toggleDetailsDrawer={toggleDetailsDrawer}
            simulationDetails={simulationDetails}
          />

          {/* <Fab
            onClick={() => {
              handleFullscreen();
            }}
            variant="extended"
            size="medium"
            color="secondary"
          >
            <FullscreenIcon />
          </Fab> */}
        </div>
      </Box>
    </>
  );
};

export default memo(SimulationPreview);
