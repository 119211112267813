import React, { memo, useEffect } from "react";
import { styled } from "@mui/system";

const Viewer = styled("iframe")(
  ({ theme }) => `
    width: 100%;
    border: none;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:focus {
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }

    #simulation-viewer canvas#canvas {
      width: -webkit-fill-available;
    }
  `
);

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const SimulationViewer = ({ id, iframeRef, src, customCSS, open }) => {
  // const iframeRef = useRef(null);
  useEffect(() => {
    const iframe = iframeRef?.current;
    if (!iframe) return;
    const injectCSS = () => {
      if (customCSS && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            type: "INJECT_CSS",
            css: customCSS,
          },
          "*"
        );
        console.log("CSS injected:", customCSS);
      }
    };
    const handleLoad = () => {
      setTimeout(injectCSS, 50);
    };
    iframe.addEventListener("load", handleLoad);
    if (iframe.contentWindow) {
      injectCSS();
    }
    return () => iframe.removeEventListener("load", handleLoad);
  }, [customCSS, iframeRef]);

  return (
    <Viewer
      id={id || "simulation-viewer"}
      ref={iframeRef}
      src={src}
      width="100%"
      height="100%"
      allow="cross-origin-isolated"
      allowFullScreen
    />
  );
};

export default memo(SimulationViewer);
