import config from "../config";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import SpokeIcon from "@mui/icons-material/Spoke";
import TuneIcon from "@mui/icons-material/Tune";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import InputIcon from "@mui/icons-material/Input";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import Hive from "@mui/icons-material/Hive";
import HandymanIcon from "@mui/icons-material/Handyman";
import ScienceIcon from "@mui/icons-material/Science";

const base_url = config.api.baseUrl;
const scheduler_base_url =
  process.env.REACT_APP_STAGE === "local" ? config.api.schedulerUrl : base_url;

console.log("base url :: " + base_url);

const Constants = {
  NAV_ROUTES_PATH_LIST: [
    "",
    "vehicles",
    "groups",
    "images",
    "releases",
    "deployments",
    "custom-configurations",
    "subscriptions",
    "commands",
    "vehicledata",
    "users",
    "reports",
    "provisioning-report",
    "scheduler-report",
    "sdv-app-features",
    "cluster-simulations",
    "tool-simulations",
    "lab-cluster-simulations",
    "lab-tool-simulations",
  ],
  NAV_ROUTES: [
    { title: "dashboard", path: "", icon: <DashboardIcon /> },
    {
      title: "vehicles",
      path: "vehiclesmenu",
      icon: <EmojiTransportationIcon />,
      children: [
        {
          title: "vehicles",
          path: "vehicles",
          icon: <DirectionsCarIcon />,
        },
        {
          title: "groups",
          path: "groups",
          icon: <SpokeIcon />,
        },
      ],
    },
    {
      title: "OTA",
      path: "otamenu",
      icon: <CloudUploadRoundedIcon />,
      children: [
        { title: "images", path: "images", icon: <UploadFileIcon /> },
        { title: "releases", path: "releases", icon: <RssFeedIcon /> },
        {
          title: "deployments",
          path: "deployments",
          icon: <RocketLaunchIcon />,
        },
      ],
    },
    {
      title: "Digital-Twin",
      path: "simulations",
      icon: <Hive />,
      children: [
        {
          title: "Clusters",
          path: "cluster-simulations",
          icon: <Hive />,
        },
        {
          title: "Truncation Tools",
          path: "tool-simulations",
          icon: <HandymanIcon />,
        },
      ],
    },
    {
      title: "Test Lab",
      path: "lab-simulations",
      icon: <ScienceIcon />,
      children: [
        {
          title: "Lab Clusters",
          path: "lab-cluster-simulations",
          icon: <Hive />,
        },
        {
          title: "Lab Truncation Tools",
          path: "lab-tool-simulations",
          icon: <HandymanIcon />,
        },
      ],
    },

    {
      title: "custom configs",
      path: "custom-configurations",
      icon: <TuneIcon />,
    },
    // {title: "subscriptions", path: "subscriptions", icon: <DynamicFeedIcon />},
    // { title: "remotecommand", path: "remotecommand", icon: <InputIcon /> },
    // { title: "logs", path: "logs", icon: <ArticleIcon /> },
    { title: "commands", path: "commands", icon: <KeyboardCommandKeyIcon /> },
    { title: "vehicle data", path: "vehicledata", icon: <ViewTimelineIcon /> },
    { title: "users", path: "users", icon: <PersonIcon /> },
    // { title: "reports", path: "reports", icon: <SummarizeIcon /> },
    {
      title: "reports",
      path: "reports",
      icon: <SummarizeIcon />,
      children: [
        {
          title: "provisioning",
          path: "provisioning-report",
          icon: <NoCrashIcon />,
        },
        {
          title: "scheduler",
          path: "scheduler-report",
          icon: <PendingActionsIcon />,
        },
      ],
    },
    {
      title: "SDV Features",
      path: "sdv-app-features",
      icon: <StayCurrentPortraitIcon />,
    },
  ],

  UPLOAD_STATUS: {
    Started: "Started",
    InProgress: "InProgress",
    Completed: "Completed",
    Failed: "Failed",
  },

  SERVER_CONFIG: `${base_url}/configuration/get`,
  SDV_SERVER_CONFIG: `${base_url}/companionapp/configuration`,

  PUBLISH_IMAGE_METADATA: `${base_url}/images`,
  PUBLISH_IMAGE_METADATA_UPLOAD_STATUS: `${base_url}/images/uploadStatus`,
  GET_IMAGE_METADATA: `${base_url}/imagemetadata`,
  LIST_IMAGES: `${base_url}/images`,
  IMAGE_REPORT: `${base_url}/reports/images`,
  DESCRIBE_IMAGE: `${base_url}/images/:id`,
  DESCRIBE_IMAGE_BY_FILE_NAME: `${base_url}/images/image/:filename`,
  STS_TOKEN: `${base_url}/images/token`,
  SDV_STS_TOKEN: `${base_url}/companionapp/token`,

  ADD_RELEASE: `${base_url}/releases`,
  LIST_RELEASES: `${base_url}/releases`,
  RELEASE_REPORT: `${base_url}/reports/releases`,
  DESCRIBE_RELEASE: `${base_url}/releases/:id`,
  VALIDATE_RELEASE: `${base_url}/releases/validate/:name`,
  GET_RELEASES_BY_IMAGE_ID: `${base_url}/releases/byimage/:imageId`,

  ADD_DEPLOYMENT: `${base_url}/deployments`,
  LIST_DEPLOYMENTS: `${base_url}/deployments`,
  PUBLISH_DEPLOYMENTS: `${base_url}/deployments/publish`,
  CANCEL_DEPLOYMENTS: `${base_url}/deployments/cancel`,
  CANCEL_VEHICLE_DEPLOYMENTS: `${base_url}/deployments/cancel/vehicle`,
  DESCRIBE_DEPLOYMENT: `${base_url}/deployments/:id`,
  LIST_JOB_EXECUTIONS_FOR_DEPLOYMENT_ID: `${base_url}/deployments/listjobexecutions/:id`,
  LIST_JOB_EXECUTIONS_FOR_VEHICLE_DEPLOYMENT_ID: `${base_url}/deployments/listjobexecutions/:deploymentId/vehicle/:vehicleKey`,
  JOB_DOCUMENT_FOR_DEPLOYMENT_ID: `${base_url}/deployments/jobdocument/:id`,
  JOB_DETAILS_FOR_DEPLOYMENT_ID: `${base_url}/deployments/jobdetail/:id`,
  JOB_STATUS_FOR_DEPLOYMENT_ID: `${base_url}/deployments/jobstatus/:id`,
  VALIDATE_DEPLOYMENT: `${base_url}/deployments/validate/:name`,
  VALIDATE_CONFIG: `${base_url}/vehicle-config/validate/:name`,

  LIST_VEHICLES: `${base_url}/vehicles`,
  LIST_VEHICLES_IN_GROUP: `${base_url}/vehicles/groupsVehicleList`,
  DESCRIBE_VEHICLE: `${base_url}/vehicles/:id`,
  VEHICLE_SHADOW: `${base_url}/vehicles/:id/shadow`,
  UPDATE_VEHICLE_SHADOW: `${base_url}/vehicles/shadow/update`,
  UPDATE_REMOTE_COMMANDS: `${base_url}/vehicles/remote-commands/update`,
  LIST_JOB_EXECUTIONS_FOR_VEHICLE_AND_DEPLOYMENT_ID: `${base_url}/vehicles/:vehicleId/deployment/:deploymentId/listjobexecutions`,
  DEPLOYMENT_DETAIL_FOR_VEHICLE: `${base_url}/vehicles/:vehicleId/deployment/:deploymentId`,
  DEPLOYMENT_AUDIT_FOR_VEHICLE: `${base_url}/vehicles/:vehicleId/deployment/:deploymentId/audit`,
  CREATE_VEHICLE_GROUP: `${base_url}/vehicles/groups`,
  GET_VEHICLE_LIST_FROM_CSV: `${base_url}/vehicles/uploadVehicleGroupFromCsv`,
  DELETE_VEHICLE_GROUP: `${base_url}/vehicles/groups/deletegroup`,
  CREATE_DYNAMIC_VEHICLE_GROUP: `${base_url}/vehicles/groups/dynamic`,
  UPDATE_DYNAMIC_VEHICLE_GROUP: `${base_url}/vehicles/groups/dynamic/update`,
  LIST_VEHICLE_GROUPS: `${base_url}/vehicles/groups`,

  LIST_SDV_APP_FEATURES: `${base_url}/sdvfeatures`,
  LIST_SDV_VEHICLE_FEATURES: `${base_url}/sdvfeatures/vehicle-in-feature`,
  LIST_VEHICLESDATA_FOR_SDV: `${base_url}/sdvfeatures/vehiclelist`,
  ADD_SDV_APP_FEATURES: `${base_url}/sdvfeatures/add-sdv-feature`,
  ADD_VEHICLE_FOR_SDV_FEATURES: `${base_url}/sdvfeatures/mapvehicle`,
  REMOVE_VEHICLE_FOR_SDV_FEATURES: `${base_url}/sdvfeatures/remove-vehicle`,
  PUBLISH_SDV_APP_FEATURES_UPLOAD_STATUS: `${base_url}/sdvfeatures/uploadstatus`,
  SDV_APP_FEATURE_UPDATE_STATUS: `${base_url}/sdvfeatures/updatefeaturestatus`,
  DESCRIBE_SDV_FEATURE: `${base_url}/sdvfeatures/:id`,
  SDV_FEATURE_REPORT: `${base_url}/reports/sdvFeature`,

  LIST_VEHICLES_IN_GROUPS: `${base_url}/vehicles/groupsVehicleList?groupsList=:groupsList`,
  GET_THINGS_IN_GROUP: `${base_url}/vehicles/group?groupName=:groupName`,
  LIST_FEATURES: `${base_url}/entitlementmanagement/features`,
  GET_VEHICLE_SUBSCRIPTIONS: `${base_url}/entitlementmanagement/subscriptions/:vehicleKey`,
  GET_VEHICLE_SUBSCRIPTIONS_AUDIT: `${base_url}/entitlementmanagement/subscriptions/audit/:vehicleKey`,
  GET_VEHICLE_FEATURES_AUDIT: `${base_url}/vehicles/:serialNo/features/audit`,
  GET_GROUP_METADATA: `${base_url}/vehicles/group/details?groupName=:groupName`,
  GET_VEHICLE_AUDIT: `${base_url}/vehicles/audit/:serialNo`,
  DESCRIBE_VEHICLE_GROUP: `${base_url}/vehicles/group/:groupId`,
  UPDATE_VEHICLE_TCU_ID: `${base_url}/vehicles/update/tcuid`,
  GET_GROUPS_ASSOCIATED_TO_VEHICLE: `${base_url}/vehicles/associatedgroups/:vehicleKey`,
  GET_ALL_TRIPS_FOR_VEHICLE: `${base_url}/vehicles/trips?serialno=:serialNo&type=:type&startdate=:startDate&enddate=:endDate`,
  GET_TRIP_DETAILS_BY_TRIP_ID: `${base_url}/vehicles/trip/details/:tripId`,

  LIST_USERS: `${base_url}/users`,
  LIST_USERS_ALL: `${base_url}/users/list`,
  LIST_UNASSIGNED_USERS: `${base_url}/users/list?type=UNASSIGNED`,
  GET_USER_GROUP: `${base_url}/users/:userId/groups`,
  GET_USER_ORGS: `${base_url}/users/:userId/organizations`,
  LIST_GROUPS: `${base_url}/users/groups`,
  ENABLE_DISABLE_USER: `${base_url}/users/:userId`,
  CONFIRM_USER: `${base_url}/users/confirm/:userId`,
  ADD_REMOVE_USER_GROUP: `${base_url}/users/:userId/groups`,
  GET_USER_DATA: `${base_url}/users/`,
  GET_USER_DATA_BY_COGNITO_ID: `${base_url}/users/cognito/currentUser`,
  GET_USER_ACTIVITY: `${base_url}/useractivity/userlogs/`,
  GET_CURRENT_USER_ACTIVITY: `${base_url}/useractivity/currentuserlogs`,
  GET_USER_ACTIVITY_FOR_YEAR: `${base_url}/useractivity/getUserActivitiesForChart?type=:type&year=:year&userid=:userid`,
  GET_USER_ACTIVITY_FOR_DATE_RANGE: `${base_url}/useractivity/getUserActivitiesForChart?type=:type&userid=:userid&from=:from&to=:to`,
  CREATE_NEW_USER: `${base_url}/users/add`,
  GET_USER_POOLS: `${base_url}/users/userpools`,
  GET_ALL_ORGS: `${base_url}/users/allorganizations`,
  UPDATE_USER_ORGS: `${base_url}/users/:userId/organizations`,
  GET_ORGS_LIST_FOR_USER: `${base_url}/users/organizations`,
  GET_CUSTOMER_DETAILS: `${base_url}/users/customer/:userId`,
  GET_CURRENT_USER_CUSTOMER_DETAILS: `${base_url}/users/customer/currentUser`,
  GET_USER_AVAILABLE_GROUPS: `${base_url}/users/:userId/availableroles`,
  UPDATE_FEATURE_STATUS: `${base_url}/entitlementmanagement/vehicle/feature`,

  DASHBOARD_GET_DEPLOYMENT_FOR_YEAR: `${base_url}/reports/deployments?group=:group&year=:year`,
  DASHBOARD_GET_DEPLOYMENT_FOR_DATE_RANGE: `${base_url}/reports/deployments?startdate=:startdate&enddate=:enddate`,
  DASHBOARD_GET_DEPLOYMENTS: `${base_url}/reports/deployments?group=:group`,
  DASHBOARD_GET_DASH_STATS: `${base_url}/reports/dashboard`,
  VEHICLES_GET_DASH_STATS: `${base_url}/reports/vehicles`,
  VEHICLE_GROUPS_GET_DASH_STATS: `${base_url}/reports/groups`,

  REPORTS_GET_PROVISIONING_DATA_FOR_DATE_RANGE: `${base_url}/reports/provisioning?type=:type&startdate=:startdate&enddate=:enddate`,
  REPORTS_GET_SCHEDULER_DATA_FOR_DATE_RANGE: `${base_url}/entitlementmanagement/schedulerlogs`,
  ENTITLEMENT_SCHEDULER_PROVISIONING: `${scheduler_base_url}/entitlement-scheduler/vehicles/provision`,
  DOWNLOAD_PROVISIONING_CSV_FILE: `${scheduler_base_url}/entitlement-scheduler/vehicles/provision/download/csv`,
  GET_PROVISIONING_CSV_LIST: `${scheduler_base_url}/entitlement-scheduler/vehicles/scan`,

  ADD_CONFIGURATION: `${base_url}/vehicle-config`,
  LIST_CONFIGURATIONS: `${base_url}/vehicle-config`,
  PUBLISH_CONFIGURATION: `${base_url}/vehicle-config/publish`,
  DESCRIBE_CONFIGURATION: `${base_url}/vehicle-config/:id`,
  JOB_STATUS_FOR_CONFIGURATION: `${base_url}/vehicle-config/jobstatus/:id`,
  JOB_DOCUMENT_FOR_CONFIGURATION: `${base_url}/vehicle-config/jobdocument/:id`,
  JOB_DETAILS_FOR_CONFIGURATION: `${base_url}/vehicle-config/jobdetail/:id`,
  JOB_EXECUTIONS_FOR_CONFIGURATION: `${base_url}/vehicle-config/listjobexecutions/:id`,

  LIST_SIMULATIONS: `${base_url}/simulations`,
  ADD_SIMULATION: `${base_url}/simulations`,
  DESCRIBE_SIMULATION_BY_NAME_FOR_CLUSTER: `${base_url}/simulations/cluster`,
  DESCRIBE_SIMULATION_BY_ID: `${base_url}/simulations/:id`,
  DESCRIBE_LAB_SIMULATION_BY_ID: `${base_url}/simulations/lab/:id`,
  UPDATE_SIMULATION_UPLOAD_STATUS: `${base_url}/simulations/uploadStatus`,
  UPDATE_SIMULATION_DESCRIPTION: `${base_url}/simulations/upload`,
  ADD_COMMENT_FOR_SIMULATION: `${base_url}/simulations/comment`,
  DELETE_COMMENT_ATTACHMENT_FOR_SIMULATION: `${base_url}/simulations/comment/:simulationId/:commentId/:filename/remove-attachment`,
  UPDATE_COMMENT_FOR_SIMULATION: `${base_url}/simulations/comment/update`,
  // GET_COMMENTS_FOR_SIMULATION: `${base_url}/simulations/comment/list`,
  GET_COMMENTS_FOR_SIMULATION_NAME: `${base_url}/simulations/comment/listall`,
  DELETE_COMMENT_FOR_SIMULATION: `${base_url}/simulations/comment/:id/remove-comment`,
  GET_COMMENT_DETAILS_BY_ID: `${base_url}/simulations/comment/details/:simulationId/:commentId`,
  CREATE_JIRA_TICKET: `${base_url}/simulations/comment/jira/create-issue`,
  ADD_JIRA_ATTACHMENT: `${base_url}/simulations/comment/jira/:jiraId`,
  SIMULATIONS_REPORT: `${base_url}/reports/simulations`,

  SOCKET_URL: base_url,

  PAGE_SIZE: 10,
  ROWS_PER_PAGE_OPT: [10, 20, 30],

  USER_STATUS: {
    CONFIRMED: "CONFIRMED",
    UNCONFIRMED: "UNCONFIRMED",
  },

  MONTHS_NAMES: [
    { id: "Jan", value: null },
    { id: "Feb", value: null },
    { id: "Mar", value: null },
    { id: "Apr", value: null },
    { id: "May", value: null },
    { id: "Jun", value: null },
    { id: "Jul", value: null },
    { id: "Aug", value: null },
    { id: "Sep", value: null },
    { id: "Oct", value: null },
    { id: "Nov", value: null },
    { id: "Dec", value: null },
  ],

  IMAGE_UPLOAD_CHANGELOG_MAX_LENGTH: 500,
  INVALID_VERSION_HELPER_TEXT:
    "Invalid version: should be in the format of xx.xx.xx",
  COMMON_ERR_MSG: "Something went wrong, please contact the administrator",
  COMMON_DEP_ERR_MSG: "The Deployment is no more mapped to this vehicle",
};

export const UPLOAD_MIN_FILESIZE = 1; //BYTES
export const HD_UPLOAD_MAX_FILESIZE = 5000000000; //5GB TO BYTES 5052342272
export const HOURS24_TO_MILLISECONDS = 86400000; //24HRS

export const GROUPS = {
  "ota-guest-group": "Guest",
  "ota-admin-group": "Admin",
  "ota-campaign-creator": "Campaign Creator",
  "ota-campaign-approver": "Campaign Approver",
  "ota-campaign-manager": "Campaign Manager",
  "ota-campaign-admin": "Campaign Admin",
  "super-admin": "Super Admin",
  "hd-admin": "HD Admin",
  "cota-admin": "Configuration Admin",
  "subscription-admin": "Subscription Admin",
  "remote-commands-admin": "Remote Commands Admin",
  "analytics-admin": "Analytics Admin",
  "simulation-creator": "Simulation Creator",
  "simulation-viewer": "Simulation Viewer",
};

export const ACTIVITY_ACTIONS = {
  AdminUserAction: {
    UserEnabled: "UserEnabled",
    UserDisabled: "UserDisabled",
    UserConfirmed: "UserConfirmed",
    RoleAdded: "RoleAdded",
    RoleRemoved: "RoleRemoved",
    UserCreated: "UserCreated",
  },
  VehicleAction: {
    Group: "Group",
    Read: "Read",
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
    KeysRegenerated: "KeysRegenerated",
  },
  VehicleGroupAction: {
    Create: "Create",
    Update: "Update",
    Delete: "Delete",
  },
  ImageUploadAction: {
    Create: "Create",
    Read: "Read",
  },
  DeploymentAction: {
    Create: "Create",
    Read: "Read",
    Publish: "Publish",
  },
  ReleaseAction: {
    Create: "Create",
    Read: "Read",
  },
};

export const ATTRUBUTE_CATEGORIES = {
  GENERIC: {
    id: "generic_attributes",
    label: "Generic Attributes",
  },
  EOL: {
    id: "eol_attributes",
    label: "End Of Line Attributes",
  },
  OEM: {
    id: "oem_attributes",
    label: "Original Equipment Manufacturer Attributes",
  },
};

export const ATTRUBUTE_CATEGORIES_LIST = [
  ATTRUBUTE_CATEGORIES.GENERIC,
  ATTRUBUTE_CATEGORIES.EOL,
  ATTRUBUTE_CATEGORIES.OEM,
];

export const VEHICLE_ATTRIBUTES = {
  soc_version: {
    label: "SOC version",
    category: ATTRUBUTE_CATEGORIES.GENERIC,
  },
  device_type: {
    label: "Device Type",
    category: ATTRUBUTE_CATEGORIES.GENERIC,
  },
  device_status: {
    label: "Device Status",
    category: ATTRUBUTE_CATEGORIES.GENERIC,
  },
  vip_version: {
    label: "VIP version",
    category: ATTRUBUTE_CATEGORIES.GENERIC,
  },
  VIN: {
    label: "Vehicle Identification Number",
    category: ATTRUBUTE_CATEGORIES.EOL,
  },
  plant_code: {
    label: "Plant Code",
    category: ATTRUBUTE_CATEGORIES.EOL,
  },
  platform: {
    label: "Platform",
    category: ATTRUBUTE_CATEGORIES.OEM,
  },
  make: { label: "Make", category: ATTRUBUTE_CATEGORIES.OEM },
  model: { label: "Model", category: ATTRUBUTE_CATEGORIES.OEM },
  year: { label: "Year", category: ATTRUBUTE_CATEGORIES.OEM },
};

export const VEHICLE_GROUP_NAME_REGEX = /[!@#$%^&*():;?/~`=+_.%<>\\$'"]/;

export const CUSTOMERS = [
  { value: "visteon", label: "Visteon" },
  { value: "HD", label: "Harley-Davidson" },
  { value: "TOYOTA", label: "Toyota" },
];

export const JOB_STATUS = {
  QUEUED: "Queued",
  IN_PROGRESS: "In progress",
  SUCCEEDED: "Succeeded",
  FAILED: "Failed",
  TIMED_OUT: "Timed out",
  REJECTED: "Rejected",
  REMOVED: "Removed",
  CANCELED: "Canceled",
};

export const AUDIT_SIGNALS = {
  DOWNLOAD_CONSENT_POPUP_SHOWN: "Download consent popup shown",
  INSTALL_CONSENT_POPUP_SHOWN: "Install consent popup shown",
  USER_ACCEPTS_DOWNLOAD: "User accepts download",
  USER_REJECTS_DOWNLOAD: "User rejects download",
  CANCEL_DOWNLOADING: "Cancel downloading",
  RESUME_DOWNLOADING: "Resume downloading",
  ALL_FILES_DOWNLOADED: "All files downloaded",
  USER_ACCEPTS_VERIFICATION: "User Accepts Verification",
  USER_REJECTS_VERIFICATION: "User Rejects Veification",
  VERIFICATION_SUCCESS: "Verification Success",
  VERIFICATION_FAILED: "Verification Failed",
  USER_ACCEPTS_INSTALL: "User accepts install",
  USER_REJECTS_INSTALL: "User rejects install",
  IN_PROGRESS: "In progress",
  COMPLETE: "Complete",
};

export const MODULES = {
  OTA: "OTA",
  SUBSCRIPTION: "SUBSCRIPTION",
  CONFIGURATION: "CONFIGURATION",
  ANALYTICS: "ANALYTICS",
  REMOTE_COMMANDS: "REMOTE_COMMANDS",
};

export const MODULE_TEXT = {
  OTA: "OTA",
  SUBSCRIPTION: "Subscription Management",
  CONFIGURATION: "Configuration Management",
  ANALYTICS: "Analytics",
  REMOTE_COMMANDS: "Remote Commands",
};

export const COMPONENTS = {
  Vehicle: "Vehicle",
  VehicleFeature: "VehicleFeature",
  VehicleGroup: "VehicleGroup",
  VehicleConfig: "VehicleConfig",
  Image: "Image",
  Release: "Release",
  Deployment: "Deployment",
  User: "User",
  PrimaryDevice: "PrimaryDevice",
  Subscription: "Subscription",
};
export const DEPLOYMENT_TARGETS_MAX_LIMIT = 100;
export const DEPLOYMENT_TYPES = {
  SNAPSHOT: "SNAPSHOT",
  CONTINUOUS: "CONTINUOUS",
};

export const DEPLOYMENT_STATUS = {
  NOT_PUBLISHED: "NOT_PUBLISHED",
  CANCELED: "CANCELED",
  PUBLISHED: "PUBLISHED",
  COMPLETED: "COMPLETED",
  SCHEDULED: "SCHEDULED",
  EXPIRED: "EXPIRED",
  INITIATED: "INITIATED",
  DRAFT: "DRAFT",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const DEPLOYMENT_PACKAGE_TYPE = {
  SMARTCORE: "SMART_CORE",
  OTHERS: "OTHERS",
};

export const SIMULATION_PACKAGE_TYPE = {
  CLUSTER: "CLUSTER",
  TOOL: "TOOL",
  OTHERS: "OTHERS",
};

export const IMAGE_UPLOAD_ERRORS = {
  UPLOAD_ABORT: "Upload aborted.",
  ERR_INTERNET_DISCONNECTED: "Upload aborted due to network issue.",
  SOMETHING_WENT_WRONG: "Something went wrong, try again.",
};

export const FILE_TYPES = {
  JSON: { accept: ".json", name: "Json" },
  ISO: { accept: ".iso", name: "Iso" },
  ALL: { accept: "*", name: "All" },
  CSV: { accept: ".csv", name: "Csv" },
  APK: { accept: ".apk", name: "Apk" },
  IMAGE: { accept: ".jpg,.jpeg,.png", name: "Image" },
  //VIDEO: { accept: "video/*", name: "Video" },
  VIDEO: { accept: ".avi,.mp4", name: "Video" },
};

export const UserRoleStatus = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
  DISABLED: "DISABLED",
};

export const UPLOAD_TYPES = {
  APK: "apk",
  IMAGE: "image",
  VIDEO: "video",
  METADATA: "metadata",
};

export const SIMULATION_UPLOAD_STATUS = {
  Started: "Started",
  InProgress: "InProgress",
  Completed: "Completed",
  Failed: "Failed",
};

export default Constants;
