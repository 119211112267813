import React from "react";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import {
  ToolImageStats,
  ToolImageUploadDataGrid,
} from "../components/labToolSimulations";

const LabToolSimulations = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ToolImageStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Lab Truncation Tools"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ToolImageUploadDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LabToolSimulations;
