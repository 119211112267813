import React, { useState, useContext } from "react";
import { useQuery } from "react-query";
import { getAllCompletedClusterImages } from "../../services/cluster";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { WizardContext, ACTIONS } from "../wizard/Context";

const ClusterImageChecklist = (props) => {
  const { state, dispatch } = useContext(WizardContext);
  const { clusterImages = [], releaseName = "" } = state;
  const { onValueUpdated, wizard } = props;
  const [checked, setChecked] = useState(wizard ? clusterImages : []);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    onValueUpdated(newChecked);

    if (wizard) {
      dispatch({
        type: ACTIONS.UPDATE_IMAGES,
        payload: {
          images: newChecked,
          disableNextBtn: releaseName === "" || newChecked.length === 0,
        },
      });
    } else {
      onValueUpdated(newChecked);
    }
  };

  const { data: { data: { res: clusterImageData = [] } = {} } = {} } = useQuery(
    "ClusterImageList",
    getAllCompletedClusterImages,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <FormControl
      component="fieldset"
      sx={{
        width: "100%",
        minHeight: 300,
        overflow: "auto",
        bgcolor: "background.paper",
        mt: 2,
      }}
      variant="standard"
    >
      <p className="helper-text">(Choose atleast one tool image)</p>
      <FormGroup>
        {clusterImageData.map((ob) => (
          <FormControlLabel
            control={
              <Checkbox
                name="vehicles"
                onClick={() => handleToggle(ob.clusterImageUploadId)}
                defaultChecked={
                  clusterImages.includes(ob.clusterImageUploadId) ? true : false
                }
              />
            }
            label={`${ob.fileName} - ${"V" + ob.version}`}
            key={ob.clusterImageUploadId}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

ClusterImageChecklist.defaultProps = {
  onValueUpdated: () => {},
  wizard: false,
};

export default ClusterImageChecklist;
