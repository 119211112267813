import React, { useState } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ClusterFileUploadDialog from "./ClusterFileUploadDialog";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useOutletContext } from "react-router-dom";

const ClusterImageUploadDataTableRowActions = ({ data }) => {
  const {
    row: { uploadStatus },
  } = data || {};
  const [openModal, setOpenModal] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(null);
  const { onAppBarToggle } = useOutletContext();

  const handleResumeUpload = (id) => {
    if (!!id) {
      setOpenModal(true);
      setResumeUpload(id);
    } else {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setResumeUpload(null);
    setOpenModal(false);
  };

  return (
    <>
      <div>
        <Button
          size="small"
          component={RouterLink}
          to={`/lab-cluster-simulations/${data.id}`}
          onClick={() => {
            onAppBarToggle();
          }}
        >
          Play
        </Button>
        {!(uploadStatus === "Failed" || uploadStatus === "Completed") && (
          <>
            <Check I={CAN.MANAGE} a={SUBJECTS.IMAGE}>
              {<span>|</span>}
              <Button size="small" onClick={() => handleResumeUpload(data?.id)}>
                Upload
              </Button>
            </Check>
          </>
        )}
      </div>
      <ClusterFileUploadDialog
        openModal={openModal}
        handleClose={handleClose}
        resumeUpload={resumeUpload}
      />
    </>
  );
};
export default ClusterImageUploadDataTableRowActions;
