import React from "react";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import {
  ClusterImageStats,
  ClusterImageUploadDataGrid,
} from "../components/labClusterSimulations";

const LabClusterSimulations = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <ClusterImageStats />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Available Lab Clusters"></CardHeader>
            <CardContent sx={{ padding: 0 }}>
              <ClusterImageUploadDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LabClusterSimulations;
