import React, { Fragment } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActionArea,
} from "@mui/material";
import { useQuery } from "react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getSimulationsData } from "../../services/cluster";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";
import ClusterSimulationFileUploadDialog from "../labClusterSimulations/ClusterSimulationFileUploadDialog";
import ClusterFileUpload from "../labClusterSimulations/ClusterFileUpload";

const ToolImageStats = () => {
  const { validateModuleWithMessage } = useModule();
  const { data: { data: { simulations = 0 } = {} } = {} } = useQuery(
    ["getSimulationsReport", { simulationType: "TOOL" }],
    getSimulationsData,
    {
      refetchOnWindowFocus: false,
    }
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: 800,
    height: 400,
    bgcolor: "background.paper",
    outline: 0,
    p: 4,
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => {
    if (!validateModuleWithMessage(MODULES.OTA)) return;
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <Box>
          <Card>
            <CardContent className="tile-cards">
              <Typography color="text.secondary" gutterBottom>
                Total Tools
              </Typography>
              <Typography
                color="text.primary"
                sx={{ fontSize: 30, fontWeight: 300 }}
                gutterBottom
              >
                {simulations}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Check I={CAN.CREATE} a={SUBJECTS.IMAGE}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <div className="dotted-card">
              <p className="dotted-text">UPLOAD NEW TOOL</p>
              <div className="dotted-icon">
                <CloudUploadIcon
                  sx={{ fontSize: "3.5rem", color: grey[500] }}
                />
              </div>
            </div>
          </CardActionArea>
          {openModal ? (
            <ClusterFileUpload
              uploadType={"TOOL"}
              handleClose={handleClose}
              openModal={openModal}
            />
          ) : null}
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
    </Fragment>
  );
};

ToolImageStats.defaultProps = {};

ToolImageStats.propTypes = {};

export default ToolImageStats;
