import React from "react";
import { getToolImagesList } from "../../services/cluster";
import Constants from "../../services/Constants";
import OTAPagination from "../OTAPagination";
import { getClusterImageUploadDataColumns } from "./ClusterImageUploadDataColumns";

const ToolImageUploadDataGrid = () => {
  const columns = getClusterImageUploadDataColumns();

  return (
    <OTAPagination
      columns={columns}
      endpoint={Constants.LIST_CLUSTER_IMAGES}
      endpointFn={getToolImagesList}
      queryName={"toolimages"}
      filterMode="server"
      getRowId={(row) => row.simulationUploadId}
    />
  );
};

export default ToolImageUploadDataGrid;
