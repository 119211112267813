import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";
import { sampleImagesList } from "./sample";

export const createClusterSimulation = async (payload) => {
  return axios.post(Constants.ADD_SIMULATION, payload);
};

export const updateClusterImageUploadStatus = async (payload) => {
  return axios.put(Constants.UPDATE_SIMULATION_UPLOAD_STATUS, payload);
};

export const updateClusterImageDescription = async (payload) => {
  return axios.put(Constants.UPDATE_SIMULATION_DESCRIPTION, payload);
};

export const getAllCompletedClusterImages = async () => {
  //Get only Images with upload status completed
  const queryStr =
    'filter={"items":[{"columnField":"uploadStatus","operatorValue":"equals","value":"Completed"}]}';
  return await axios.get(Constants.LIST_SIMULATIONS + `?${queryStr}`);
};

export const getStsToken = async () => {
  return await axios.get(Constants.STS_TOKEN);
};

export const getSimulationsData = async ({ queryKey }) => {
  let query = Constants.SIMULATIONS_REPORT;
  const simulationType = queryKey?.[1]?.simulationType;
  return await axios.get(query + `?simulationtype=${simulationType}`);
};

export const getServerConfiguration = async () => {
  return await axios.get(Constants.SERVER_CONFIG);
};

export const getClusterImagesList = async ({ queryKey }) => {
  const SAMPLE_DATA = sampleImagesList;
  // return { data: SAMPLE_DATA }; // TODO:X
  const [_, page, pageSize, field, sort, filterValue, columns] = queryKey;
  let query =
    Constants.LIST_SIMULATIONS +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;
  const defaultFilterItem = {
    columnField: "simulationType",
    operatorValue: "equals",
    value: "CLUSTER",
  };
  if (filterValue && filterValue?.items) {
    let typeExists = false;
    filterValue.items.some((item, index) => {
      if (item.columnField === "simulationType") {
        filterValue.items[index].value = "CLUSTER";
        typeExists = true;
        return true;
      }
      return false;
    });
    if (!typeExists) {
      filterValue?.items.push(defaultFilterItem);
    }
  } else {
    filterValue["items"] = [defaultFilterItem];
  }
  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const getToolImagesList = async ({ queryKey }) => {
  const SAMPLE_DATA = sampleImagesList;
  // return { data: SAMPLE_DATA }; // TODO:X
  const [_, page, pageSize, field, sort, filterValue, columns] = queryKey;

  let query =
    Constants.LIST_SIMULATIONS +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  const defaultFilterItem = {
    columnField: "simulationType",
    operatorValue: "equals",
    value: "TOOL",
  };
  if (filterValue && filterValue?.items) {
    let typeExists = false;
    filterValue.items.some((item, index) => {
      if (item.columnField === "simulationType") {
        filterValue.items[index].value = "TOOL";
        typeExists = true;
        return true;
      }
      return false;
    });
    if (!typeExists) {
      filterValue?.items.push(defaultFilterItem);
    }
  } else {
    filterValue["items"] = [defaultFilterItem];
  }
  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const getClusterImageByName = async ({ queryKey }) => {
  const url = Constants.DESCRIBE_SIMULATION_BY_NAME_FOR_CLUSTER;
  const fileName = queryKey?.[1]?.name;
  const simulationType = queryKey?.[1]?.simulationType;
  const { data } = await axios.get(
    url + `?simulationtype=${simulationType}&filename=${fileName}`
  );
  return data;
};

export const getClusterImageById = async ({ queryKey }) => {
  console.log("query key data -", queryKey);
  const [_, id] = queryKey;
  const url = Constants.DESCRIBE_SIMULATION_BY_ID.replace(":id", id);
  const { data } = await axios.get(url);
  return data;
};
export const getLabClusterImageById = async ({ queryKey }) => {
  console.log("query key data -", queryKey);
  const [_, id] = queryKey;
  const url = Constants.DESCRIBE_LAB_SIMULATION_BY_ID.replace(":id", id);
  const { data } = await axios.get(url);
  return data;
};

export const addCommentForSimulation = async (payload) => {
  return axios.post(Constants.ADD_COMMENT_FOR_SIMULATION, payload);
};

export const updateCommentForSimulation = async (payload) => {
  return axios.post(Constants.UPDATE_COMMENT_FOR_SIMULATION, payload);
};

// export const getCommentsForSimulation = async ({ queryKey }) => {
//   const [
//     _,
//     page,
//     pageSize,
//     field,
//     sort,
//     filterValue,
//     columns,
//     simulationUploadId,
//   ] = queryKey;
//   let query =
//     Constants.GET_COMMENTS_FOR_SIMULATION +
//     `?simulationUploadId=${simulationUploadId}&page=${page}&take=${pageSize}&sortby=createdAt&order=${sort.toUpperCase()}`;

//   query = constructQueryUrl({ filterValue, query, columns });
//   return await axios.get(query);
// };

export const getCommentsForSimulationName = async ({ queryKey }) => {
  const [
    _,
    page,
    pageSize,
    field,
    sort,
    filterValue,
    simulationName,
    simulationType,
  ] = queryKey;
  let query =
    Constants.GET_COMMENTS_FOR_SIMULATION_NAME +
    `?simulationname=${simulationName}&simulationtype=${simulationType}`;

  query = constructQueryUrl({ filterValue, query });
  return await axios.get(query);
};

export const deleteCommentAttachmentForSimulation = async ({
  simulationUploadId,
  simulationCommentId,
  fileName,
}) => {
  return axios.delete(
    Constants.DELETE_COMMENT_ATTACHMENT_FOR_SIMULATION.replace(
      ":simulationId",
      simulationUploadId
    )
      .replace(":commentId", simulationCommentId)
      .replace(":filename", fileName)
  );
};
export const deleteCommentForSimulation = async (id) => {
  return axios.delete(
    Constants.DELETE_COMMENT_FOR_SIMULATION.replace(":id", id)
  );
};

export const getCommentDetails = async ({ queryKey }) => {
  const [_, commentId, simulationId] = queryKey;
  const url = Constants.GET_COMMENT_DETAILS_BY_ID.replace(
    ":commentId",
    commentId
  ).replace(":simulationId", simulationId);
  const { data } = await axios.get(url);
  return data;
};

export const addJiraTicketAttachment = async (payload, jiraIssueId) => {
  return axios.post(
    Constants.ADD_JIRA_ATTACHMENT.replace(":jiraId", jiraIssueId),
    payload
  );
};

export const createJiraTicket = async (payload) => {
  return axios.post(Constants.CREATE_JIRA_TICKET, payload);
};
